import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type CreateWorkspaceRequest = {
	userId: string;
	workspace: {
		name: string;
		url: string;
	};
};

export type CreateWorkspaceResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
};

export type GetWorkspaceRequest = {
	workspaceId: string;
};

export type GetWorkspaceResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
} | null;

export type GetWorkspaceByUrlRequest = {
	url: string;
};

export type GetWorkspaceByUrlResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
} | null;

export type UpdateWorkspaceRequest = {
	workspace: {
		name: string;
		url: string;
	};
	workspaceId: string;
};

export type UpdateWorkspaceResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
};

export type DeleteWorkspaceRequest = {
	workspaceId: string;
};

export type GetUserWorkspacesRequest = {
	userId: string;
};

export type GetUserWorkspacesResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
}[];

export type JoinWorkspaceRequest = {
	token: string;
	userId: string;
};

export type JoinWorkspaceResponse = {
	Labels: {
		color: string;
		description: string | null;
		id: string;
		name: string;
		workspaceId: string;
	}[];
	admins: string[];
	avatarUrl: string | null;
	companySize: number | null;
	id: string;
	name: string;
	tasksCreated: number;
	universalTokenLinkId: string | null;
	url: string;
} | null;

export type InviteToWorkspaceRequest = {
	email: string | string[];
	workspaceId: string;
};

/**
 * workspace service
 */
export class WorkspaceService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "workspace");
	}

	/**
	 * createWorkspace method
	 */
	createWorkspace(
		ctx: Context,
		req: CreateWorkspaceRequest,
	): Promise<CreateWorkspaceResponse> {
		return this.request(ctx, "createWorkspace", req);
	}

	/**
	 * getWorkspace method
	 */
	getWorkspace(
		ctx: Context,
		req: GetWorkspaceRequest,
	): Promise<GetWorkspaceResponse> {
		return this.request(ctx, "getWorkspace", req);
	}

	/**
	 * getWorkspaceByUrl method
	 */
	getWorkspaceByUrl(
		ctx: Context,
		req: GetWorkspaceByUrlRequest,
	): Promise<GetWorkspaceByUrlResponse> {
		return this.request(ctx, "getWorkspaceByUrl", req);
	}

	/**
	 * updateWorkspace method
	 */
	updateWorkspace(
		ctx: Context,
		req: UpdateWorkspaceRequest,
	): Promise<UpdateWorkspaceResponse> {
		return this.request(ctx, "updateWorkspace", req);
	}

	/**
	 * deleteWorkspace method
	 */
	deleteWorkspace(ctx: Context, req: DeleteWorkspaceRequest): Promise<void> {
		return this.request(ctx, "deleteWorkspace", req);
	}

	/**
	 * getUserWorkspaces method
	 */
	getUserWorkspaces(
		ctx: Context,
		req: GetUserWorkspacesRequest,
	): Promise<GetUserWorkspacesResponse> {
		return this.request(ctx, "getUserWorkspaces", req);
	}

	/**
	 * joinWorkspace method
	 */
	joinWorkspace(
		ctx: Context,
		req: JoinWorkspaceRequest,
	): Promise<JoinWorkspaceResponse> {
		return this.request(ctx, "joinWorkspace", req);
	}

	/**
	 * inviteToWorkspace method
	 */
	inviteToWorkspace(
		ctx: Context,
		req: InviteToWorkspaceRequest,
	): Promise<void> {
		return this.request(ctx, "inviteToWorkspace", req);
	}
}
